
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get("token")
    const merchantId = searchParams.get("merchantId")
    const lang = searchParams.get("lang")

    let template = `<tint-vto></tint-vto>`
    if (token) {
      template = `<tint-vto token="${token}"></tint-vto>`
    }
    if (merchantId) {
      template = `<tint-vto merchant-id="${merchantId}" skip-load-sdk-token></tint-vto>`
    }
    if (lang) {
      template = `<tint-vto lang="${lang}" skip-load-sdk-token></tint-vto>`
    }

    document.body.innerHTML += template

    window.TINT_GVTO = document.querySelector("tint-vto")
  